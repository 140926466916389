.home__container {
  display: flex;
  width: 70%;
  margin: 100px auto;
  align-items: center;
}

.home__description {
  min-width: 50%;
}

.home__hero {
  max-width: 50%;
  height: auto;
}

button {
  background-color: var(--first-color);
  color: var(--body-color);
  font-weight: var(--font-semi-bold);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.75rem 1.5rem;
}

@media screen and (max-width: 950px) {
  .home__hero {
    display: none;
  }
}
