.form__container {
  width: 70%;
  margin: auto;
}

@media screen and (max-width: 1150px) {
  .form__container {
    width: 100%;
  }
}

form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
}

input {
  width: 100%;
  height: 35px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);

  &:focus {
    border: 2px solid var(--first-color);
  }
}

textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);

  &:focus {
    border: 2px solid var(--first-color);
  }
}

label {
  margin-top: 1rem;
  font-weight: 600;
}

input[type='submit'] {
  margin-top: 2rem;
  cursor: pointer;
  background-color: var(--first-color);
  color: var(--body-color);
  font-weight: 800;
  font-size: large;
  border: none;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
