.services__container {
  height: 100%;
  width: 70%;
  margin: 0 auto 100px auto;
}

.services__header {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5rem 10rem;
  max-width: 100%;
}

.subheader {
  margin: auto;
  text-align: center;
  height: 100%;
}

.row:after {
  content: '';
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 45%;
  padding: 10px;
}

.services__image_container {
  height: 300px;
  line-height: 300px;
  text-align: center;
}

.services__image {
  vertical-align: middle;
}

@media screen and (max-width: 700px) {
  .column {
    width: 100%;
  }
}

@media screen and (max-width: 950px) {
  .services__image_container {
    display: none;
  }
}
