@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface:wght@700&family=Montserrat:wght@400;600;700&display=swap');

:root {
  --header-height: 3.5rem;
  --first-color: hsl(157, 41%, 34%);
  --title-color: hsl(0, 0%, 0%);
  --text-color: hsl(0, 0%, 0%);
  --body-color: hsl(33, 24%, 91%);
  --body-font: 'Montserrat', sans-serif;
  --second-font: 'Abril Fatface', serif;
  --h2-font-size: 1.25rem;
  --small-font-size: 0.813rem;
  --font-semi-bold: 600;
  --z-tooltip: 10;
  --z-fixed: 100;
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
  font-family: var(--body-font);
  line-height: 200%;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
